import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dictionaryTranslation',
  standalone: true
})
export class DictionaryTranslationPipe implements PipeTransform {

  dictionary: { term: string, translation: string }[] = [
    { term: 'currency', translation: 'Para Birimi' },
    { term: 'currentValue', translation: 'Rayiç Değer' },
    { term: 'dailyExchangeRate', translation: 'Günlük Kur' },
    { term: 'dailyValue', translation: 'Günlük Birim Fiyat' },
    { term: 'daysOfMaturity', translation: 'Vadeye Kalan Gün' },
    { term: 'Description', translation: 'Açıklama' },
    { term: 'Duration', translation: 'Durasyon' },
    { term: 'ExchangeContractNo', translation: 'Borsa Sözleşme No' },
    { term: 'ExecutionDate', translation: 'Bağlanış Tarihi' },
    { term: 'groupRatio', translation: 'Grup(%)' },
    { term: 'interestPaymentCount', translation: 'Faiz Ödeme Sayısı' },
    { term: 'internalDiscountRate', translation: 'İç İskonto Oranı' },
    { term: 'investmentInstrumentCode', translation: 'Varlık Kodu' },
    { term: 'investmentInstrumentName', translation: 'Varlık Adı' },
    { term: 'investmentInstrumentType', translation: 'Varlık Tipi' },
    { term: 'isForeign', translation: 'Yerli / Yabancı' },
    { term: 'isinCode', translation: 'ISIN Kodu' },
    { term: 'issuer', translation: 'İhraççı Kurum' },
    { term: 'MaturityDate', translation: 'Vade Tarihi' },
    { term: 'NetReturnAmount', translation: 'Net Dönüş Tutarı' },
    { term: 'NominalInterestRate', translation: 'Nominal Faiz Oranı' },
    { term: 'nominalValue', translation: 'Nominal Değer' },
    { term: 'OnRatio', translation: 'O/N' },
    { term: 'PortONRatio', translation: 'PortO/N' },
    { term: 'portfolioDate', translation: 'Portföy Tarihi' },
    { term: 'PurchaseDate', translation: 'Satın Alış Tarihi' },
    { term: 'RepoGuaranteeAmount', translation: 'Repo Teminat Tutarı' },
    { term: 'sector', translation: 'Sektör' },
    { term: 'stockEstablishment', translation: 'Şirket Grubu' },
    { term: 'tcmbCode', translation: 'TCMB Kodu' },
    { term: 'totalByFPD', translation: 'TOPLAM (FPD GÖRE)' },
    { term: 'totalByFTD', translation: 'TOPLAM (FTD GÖRE)' },
    { term: 'UnitPurchasePrice', translation: 'Birim Alış Fiyatı' },
    { term: 'UsdValueOfCurrentValue', translation: 'USD Değeri' },
    { term: 'portfolioCode', translation: 'Portföy Kodu'},
    { term: 'fundCategory', translation: 'Fon Kategorisi'},
    { term: 'fundSubCategoryName', translation: 'Fon kategorisi'},
    { term: 'initialPublicOfferingDate', translation: 'Halka Arz Tarihi'},
    { term: 'investmentInstrumentMainType', translation: 'Varlık Asıl Tipi'},
    { term: 'investmentInstrumentSubType', translation: 'Varlık Alt Tipi'},
    { term: 'additionalCode', translation: 'Ek Kod'},
    { term: 'annotation', translation: 'Ek Açıklama'},
    { term: 'substituteCode', translation: 'Değiştirildiği Kod'},
    { term: 'substituteType', translation: 'Değiştirildiği Tip'},
    { term: 'issueDate', translation: 'İhraç Tarihi'},
    { term: 'redemptionDate', translation: 'Vade Tarihi'},
    { term: 'forexType', translation: 'Döviz Tipi'},

  ];

  transform(term: string): string {
    const translation = this.dictionary.find(item => item.term === term)?.translation;
    return translation || term;
  }

}

import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenericHttpService } from './generic-http.service';
import { User } from 'src/app/components/user-settings/models/user.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoginService } from 'src/app/components/login/services/login.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private jwtHelper: JwtHelperService,
    private httpService: GenericHttpService,
    private router: Router
  ) {}
  public isAuthenticatedSubject = new BehaviorSubject<boolean>(this.hasToken());
  public fullName = new BehaviorSubject<string>(this.hasName());
  public userRole = new BehaviorSubject<string>(this.hasRole());
  module: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem('modul') || '');
  public userRoleSubject = new BehaviorSubject<string | null>(
    this.getRoleFromLocalStorage()
  );
  userRole$ = this.userRoleSubject.asObservable();
  private userIdSubject = new BehaviorSubject<number | null>(
    this.getUserIdFromLocalStorage()
  );
  userId$ = this.userIdSubject.asObservable();
  identityCheck() {
    const token = localStorage.getItem('accessToken');
    let expired: boolean;

    try {
      expired = this.jwtHelper.isTokenExpired(token);
    } catch (error) {
      expired = true;
    }

    _isAuthenticated = token != null && !expired;
  }
  private getRoleFromLocalStorage(): string | null {
    return localStorage.getItem('userRole');
  }
  setUserId(userId: number) {
    // localStorage.setItem('userId', userId.toString());
    this.userIdSubject.next(userId);
  }
  getUserId(): number | null {
    return this.userIdSubject.value;
  }
  clearUserId() {
    this.userIdSubject.next(null);
  }
  decodeToken() {
    let isAuth:boolean
    this.isAuthenticatedSubject.asObservable().subscribe(res=>{
      isAuth = res
    })

    const token = localStorage.getItem('accessToken');
    if (token && isAuth) {
      const parts = token.split('.');

      // Check if token has exactly 3 parts
      if (parts.length === 3) {
        try {
          // Decode base64 and parse JSON
          const base64Payload = parts[1];

          // Handle URL safe base64 encoding if necessary
          const base64 = base64Payload.replace(/-/g, '+').replace(/_/g, '/');
          const jsonPayload = atob(base64);
          const payload = JSON.parse(jsonPayload);

          return { payload };
        } catch (e) {
          console.error('Error decoding token:', e);
          return null;
        }
      } else {
        console.error('Invalid token format');
        return null;
      }
    } else {
      return null;
    }
  }

  private getUserIdFromLocalStorage(): number | null {
    var res = this.decodeToken();
    return res?.payload?.userId ? parseInt(res?.payload?.userId, 10) : null;
  }
  setUserRole(role: string) {
    localStorage.setItem('userRole', role);
    this.userRoleSubject.next(role);
  }
  setRole(role: string): void {
    this.userRoleSubject.next(role);
  }
  clearUserRole() {
    localStorage.removeItem('userRole');
    this.userRoleSubject.next(null);
  }
  get isAuthenticated(): boolean {

    return _isAuthenticated;
  }
  private hasToken(): boolean {
    const token = localStorage.getItem('accessToken');
    let expired: boolean;

    try {
      expired = this.jwtHelper.isTokenExpired(token);
    } catch (error) {
      expired = true;
    }
    return token !== null && !expired;
  }
  private hasName(): string {
    return localStorage.getItem('fullName') || '';
  }
  private hasRole() {
    return localStorage.getItem('userRole') || '';
  }
  private hasModule() {
    return localStorage.getItem('modul') || '';
  }
  isLoggedIn(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }
  getName(): Observable<string> {
    return this.fullName.asObservable();
  }
  public observeUserRole(): Observable<string> {
    return this.userRole.asObservable();
  }
  public observeModule(): Observable<string> {
    return this.module.asObservable();
  }
  logout() {
    localStorage.clear();
    this.isAuthenticatedSubject.next(false); // Oturum kapatıldığında tüm abonelere false değerini gönderir
    this.router.navigate(['/login']);
  }
  getRole(callBack: (res: any) => void) {
    this.httpService.getWithoutParams('Authorization/Roles', callBack);
  }
  getUsers(callBack: (res: User[]) => void) {
    this.httpService.getWithoutParams('Authorization/Users', callBack);
  }
  getUsersModule(callBack: (res: User[]) => void) {
    this.httpService.getWithoutParams('Authorization/GetUsersModule', callBack);
  }
  getUserListByUserId(Id: number): any {
    const params = new HttpParams().set('Id', Id.toString());
    return this.httpService.getReturn('Authorization/UserListById', params);
  }
  getUserModul(Id: number): Promise<number[]> {
    const params = new HttpParams().set('Id', Id.toString());
    return this.httpService.getReturn('Authorization/UserModul', params);
  }
}
export let _isAuthenticated: boolean;
